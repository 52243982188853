import { Vue } from "vue-property-decorator";
import URLS, { UrlsVO } from "../domain/UrlsVO";

//@ts-ignore
import Modal from "../components/shared/modal/Modal.vue";
// @ts-ignore
import { Bus as VuedalsBus } from "vuedals";

export default class BaseComponent extends Vue {
    urls: UrlsVO = URLS;

    showAlertModal(msg: string) {
        VuedalsBus.$emit("new", {
            component: Modal,
            props: {
                msg: msg,
                type: "warning",
            },
        });
    }
    
    showSuccessModal(msg: string) {
        VuedalsBus.$emit("new", {
            component: Modal,
            props: {
                msg: msg,
                type: "success",
            },
        });
    }

    showErrorModal(msg: string) {
        VuedalsBus.$emit("new", {
            component: Modal,
            props: {
                msg: msg,
                type: "error",
            },
        });
    }
}
