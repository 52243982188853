import { getModule } from "vuex-module-decorators";
import { VuexStore } from "../domain/VuexStore";
import BaseComponent from "cathedra-aulas-vue/src/components/BaseComponent";

export default class VendasBaseComponent extends BaseComponent {
    // @ts-ignore
    store: VuexStore = getModule(VuexStore, this.$store);

    addMercadoPagoScript(tagId: string, viewType: string) {
        const divEl = document.getElementById(tagId);
        if (divEl) {
            const newSCript = document.createElement("script");
            newSCript.setAttribute("src", "https://www.mercadopago.com/v2/security.js");
            newSCript.setAttribute("view", viewType);
            divEl.appendChild(newSCript);
            console.log("Script mercado pago adicionado.");
        } else {
            console.warn(`Tag com id="${tagId}" não encontrada nesta página.`);
        }
    }
}
