import AxiosHttp from "../AxiosHttp";
import Estado from "./Estado";

export default class GeralService extends AxiosHttp {
    static estados: Estado[] = [];

    constructor() {
        super();
    }

    listarEstados(): Promise<any> {
        if (GeralService.estados.length == 0) {
            return this.http.get(`estado`).then(res => {
                // @ts-ignore
                GeralService.estados = res.data;
                return res && res.status == 200 ? GeralService.estados : null;
            });
        } else {
            return Promise.resolve(GeralService.estados);
        }
    }

    listarMunicipios(siglaEstado: string): Promise<any> {
        return this.http.get(`estado/${siglaEstado}/municipio`).then(res => {
            return res && res.status == 200 ? res.data : null;
        });
    }

    /**
     *
     * @returns Vem do SystemInfoFacade
     */
    version() {
        return this.http.get(`system/version`).then((res: any) => {
            return res.status == 200 ? res.data : null;
        });
    }
}
