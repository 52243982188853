
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class CampoTexto extends Vue {
    // name: "campo-texto",

    @Prop({ required: false, default: "Sem label" })
    label: string;

    @Prop({ required: false, default: null })
    value: string;

    @Prop({ required: false, default: false })
    password: boolean;

    @Prop({ required: false, default: false })
    hasError: boolean;

    @Prop({ required: false, default: false })
    isValid: boolean;

    @Prop({ required: false, default: false })
    disabled: boolean;

    get idInterno() {
        // @ts-ignore
        return "campo-texto-" + this._uid;
    }
}
