import { render, staticRenderFns } from "./CampoTexto.vue?vue&type=template&id=0722ecc2&"
import script from "./CampoTexto.vue?vue&type=script&lang=ts&"
export * from "./CampoTexto.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../../../cathedra-vendas/cathedra-vendas-web/src/main/ui-vendas/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports