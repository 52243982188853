import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/pages/home/Home.vue";
import Constantes from "cathedra-aulas-vue/src/domain/Constantes";

Vue.use(VueRouter);

/**
 * Uma mistura de ROTAS e menu, onde alguns itens não devem ser valor cliváveis, apesar de aparecer no menu.
 */
const menu = [
    { path: "*", component: Home, meta: { titulo: "Home", menu: false } },
    {
        path: "/",
        name: "home",
        component: Home,
        meta: { titulo: "Home", menu: true, icon: "fas fa-chart-pie", exibirTitulo: false },
    },
    {
        path: "/carrinho",
        name: "carrinho",
        component: () => import("../components/pages/vendas/Carrinho.vue"),
        meta: { titulo: "Carrinho", menu: true, icon: "fas fa-shopping-cart" },
        perfisPermitidos: [Constantes.PERFIL_QUALQUER],
    },
    {
        path: "/usuario/criar",
        name: "usuario_criar",
        component: () => import("../components/pages/usuario/UsuarioCriarPage.vue"),
        meta: { titulo: "Criar usuário", exibirTitulo: true, icon: "fas fa-user" },
        perfisPermitidos: [Constantes.PERFIL_QUALQUER],
    },
    {
        path: "/produtos",
        name: "produtos",
        component: () => import("../components/pages/vendas/CursosVenda.vue"),
        meta: { titulo: "Produtos", menu: false, icon: "fas fa-file-alt" },
        perfisPermitidos: [Constantes.PERFIL_QUALQUER],
    },

    {
        path: "/checkout",
        name: "checkout",
        component: () => import("../components/pages/vendas/Checkout.vue"),
        meta: { titulo: "Checkout", menu: false, icon: "fas fa-abacus" },
        perfisPermitidos: [Constantes.PERFIL_QUALQUER],
    },
    {
        path: "/checkoutAcabou",
        name: "checkoutAcabou",
        component: () => import("../components/pages/vendas/CheckoutAcabou.vue"),
        meta: { titulo: "Obrigado!", menu: false, icon: "fas fa-thumbs-up", exibirTitulo: false },
        exibirTitulo: true,
        perfisPermitidos: [Constantes.PERFIL_QUALQUER],
    },
    {
        path: "/produto/:id",
        name: "produto",
        component: () => import("../components/pages/produto/ProdutoVendaPage.vue"),
        meta: { titulo: "Produto", menu: false, icon: "fas fa-book-open", exibirTitulo: false },
        perfisPermitidos: [Constantes.PERFIL_QUALQUER],
    },
];

const routes = [];
menu.forEach((m) => {
    if (m.path) {
        routes.push(m);
    } else if (m.itens) {
        m.itens.forEach((m2) => {
            if (m2.path) routes.push(m2);
        });
    }
});

const router = new VueRouter({
    routes,
});

export { router, routes, menu };
