import AxiosHttp from "../AxiosHttp";
import Produto from "./Produto";

export default class ProdutoService extends AxiosHttp {
    constructor() {
        super();
    }

    listarProdutosAleatorios(qtde: number): Promise<any> {
        return this.http.get(`produto/random`, { params: { qtde: qtde } }).then((res: any) => {
            return res.status == 200 ? res.data : null;
        });
    }

    obterProduto(produtoId: number): Promise<any> {
        return this.http.get(`produto/${produtoId}`).then((res: any) => {
            return res.status == 200 ? res.data : null;
        });
    }

    buscarProduto(textoPesquisa: string) {
        return this.http.get(`produto`, { params: { textoPesquisa: textoPesquisa } }).then((res: any) => {
            return res.status == 200 ? res.data : null;
        });
    }

    inserirProduto(produto: Produto) {
        return this.http.post(`produto`, produto).then((res: any) => {
            return res.status == 200 ? res.data : null;
        });
    }

    alterarProduto(produto: Produto) {
        return this.http.put(`produto`, produto).then((res: any) => {
            return res.status == 200 ? res.data : null;
        });
    }
}
