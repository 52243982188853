
import { Vue, Component, Prop } from "vue-property-decorator";
import VendasSiteService from "../../../domain/vendas/VendasSiteService";
import CarrinhoItemEntity from "cathedra-aulas-vue/src/domain/Vendas/CarrinhoItemEntity";
import Produto from "cathedra-aulas-vue/src/domain/produto/Produto";

import Utils from "cathedra-aulas-vue/src/domain/Utils";

@Component({ components: {} })
export default class ProdutoPainel extends Vue {
    vendasService: VendasSiteService = new VendasSiteService();

    @Prop({ required: true })
    value: Produto;

    get imageSrc(): string {
        if (this.value.imagem) return atob(this.value.imagem.imagem);
        else return require("../../../assets/img/curso.webp");
    }

    adicionarAoCarrinho() {
        this.vendasService
            .adicionarAoCarrinho(new CarrinhoItemEntity(this.value, 1))
            .then(() => this.$router.push("/carrinho"))
            .catch((msg) => Utils.exibirErro(msg));
    }

    exibirProduto(id: number) {
        // @ts-ignore
        // this.$jQuery("html, body").animate({ scrollTop: 0 }, "fast");
        this.$router.push(`/produto/${id}`);
    }
}
