
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import ProdutoService from "cathedra-aulas-vue/src/domain/produto/ProdutoService";
import VendasBaseComponent from "../../VendasBaseComponent";
import ProdutoPainel from "./ProdutoPainel.vue";

@Component({ components: { ProdutoPainel } })
export default class ProdutosPainel extends VendasBaseComponent {
    cursos: Array<any> = [];
    produtoService: ProdutoService = new ProdutoService();
    
    @Prop({ required: false, default: 10 })
    qtde: number;

    @Watch("$loggedUser.perfis")
    loggedUserPerfisChanged() {
        Vue.nextTick().then(() => this.atualizar());
    }

    mounted() {
        Vue.nextTick().then(() => this.atualizar());
    }

    atualizar() {
        this.produtoService.listarProdutosAleatorios(this.qtde).then((res) => {
            this.cursos = res;
        });
    }
}
