import { render, staticRenderFns } from "./TransitionHeight.vue?vue&type=template&id=16f543ce&"
import script from "./TransitionHeight.vue?vue&type=script&lang=js&"
export * from "./TransitionHeight.vue?vue&type=script&lang=js&"
import style0 from "./TransitionHeight.vue?vue&type=style&index=0&id=16f543ce&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../../cathedra-vendas/cathedra-vendas-web/src/main/ui-vendas/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports