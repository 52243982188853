import SegurancaService from "cathedra-aulas-vue/src/domain/Seguranca/SegurancaService";
import Usuario from "cathedra-aulas-vue/src/domain/Seguranca/Usuario";
import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import { store, VuexStore } from "../VuexStore";

export default class SegurancaSiteService extends SegurancaService {
    // @ts-ignore
    store: VuexStore = getModule(VuexStore, store);

    logar(usuario: Usuario) {
        return super.logar(usuario).then((usu: any) => {
            this.store.loggedUser.username = usu + "";
            return usu;
        });
    }

    updateLoggedUser() {
        this.loggedUser()
            .then((value: any) => {
                if (value && value.username && value.username.length > 1) {
                    value.perfis = [];
                    this.store.setLoggedUser(value);

                    this.listarPermissoesDeUsuario(value.username).then((perfis: any) => {
                        // @ts-ignore
                        const loggedUser = this.store.loggedUser;
                        // @ts-ignore
                        loggedUser.perfis = perfis;
                        this.store.setLoggedUser(loggedUser);
                    });
                } else {
                    Vue.$cookies.set("jwt", "");
                    this.store.setLoggedUser(new Usuario());
                }
            })
            .catch((error: any) => {
                // @ts-ignore
                this.$showErrorModal("Erro de acesso aos dados: " + error);
            });
    }
}
