//@ts-ignore
import jQuery from "jquery";

import "admin-lte/plugins/jquery/jquery";
import "admin-lte/plugins/jquery-ui/jquery-ui";
import "admin-lte/plugins/popper/popper";

import "./assets/css/app.scss";

import "admin-lte/plugins/bootstrap/js/bootstrap.bundle";
import "autonumeric/dist/autoNumeric.min";

import "@fortawesome/fontawesome-free/css/all.css";
import "primeicons/primeicons.css";

// SCSS deixou eu remover isso: import "admin-lte/dist/css/adminlte.min.css";
import "admin-lte/dist/js/adminlte.min";
import "admin-lte/plugins/icheck-bootstrap/";
import "toastr/build/toastr.min";
import "toastr/build/toastr.min.css";

import Vue from "vue";
//@ts-ignore
import App from "./App.vue";

import VueRouter from "vue-router";
//@ts-ignore
import { router } from "./configs/router";
//@ts-ignore
import moment from "moment";
import "moment/locale/pt-br";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import "./validation";
//@ts-ignore
import Modal from "./components/shared/modal/Modal.vue";
import VueCookies from "vue-cookies";
//@ts-ignore
import NProgress from "vue-nprogress";
//@ts-ignore
import { default as Vuedals, Component as Vuedal, Bus as VuedalsBus } from "vuedals";
//@ts-ignore
import PrimeVue from "primevue/config";
//@ts-ignore
import primeVueConfig from "./configs/primeVueConfig";

import SegurancaService from "cathedra-aulas-vue/src/domain/Seguranca/SegurancaService";
//@ts-ignore
import Constantes from "cathedra-aulas-vue/src/domain/Constantes";
import "./assets/css/style.css";

import AxiosHttp from "cathedra-aulas-vue/src/domain/AxiosHttp";

import { store, VuexStore } from "./domain/VuexStore";
import { getModule } from "vuex-module-decorators";

Object.defineProperty(Vue.prototype, "$jQuery", { value: jQuery });

import "select2/dist/js/select2.full.min";
import "select2/dist/css/select2.min.css";
import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css";

Vue.use(VueRouter);
Vue.use(VueCookies);
Vue.$cookies.config("7d");

// Para Sempre exibir o progresso quando enviar requisição AJAX
Vue.use(NProgress);
const nprogress = new NProgress();

Vue.prototype.$http = new AxiosHttp().http;

Vue.use(Vuedals);
Vue.use(PrimeVue, primeVueConfig);

Vue.component("vee-validate", ValidationProvider);
Vue.component("vee-observer", ValidationObserver);
Vue.component("modal", Modal);
Vue.component("vuedal", Vuedal);

const realFormater = Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
});
const percFormater = Intl.NumberFormat("pt-BR", {
    style: "percent",
    currency: "BRL",
    minimumFractionDigits: 2,
});

Vue.filter("date", function (value: string | object | number) {
    if (value != 0 && !value) return "";
    else {
        return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
    }
});

Vue.filter("dateTime", function (value: string | object | number) {
    if (value != 0 && !value) return "";
    else {
        return moment(value, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY HH:mm:ss");
    }
});
Vue.filter("toDate", function (value: string | object | number) {
    if (typeof value !== "string") {
        return value;
    }
    return moment(value).format("L");
});

Vue.filter("toDateTime", function (value: string | object | number) {
    if (typeof value !== "string") {
        return value;
    }
    return moment(value).format("L LT");
});

Vue.filter("toTime", (value: string | object | number) => {
    if (typeof value !== "string") {
        return value;
    }
    return moment(value).format("LT");
});

Vue.filter("toCurrency", (value: number, defaultReplaceValue: string = "") => {
    if (value != 0 && !value) return defaultReplaceValue;
    else {
        return realFormater.format(value);
    }
});
Vue.filter("toPercent", (value: number) => {
    if (value != 0 && !value) return "";
    else {
        return percFormater.format(value);
    }
});

Vue.prototype.$SegurancaService = SegurancaService;

const vuexStore = getModule(VuexStore, store) as VuexStore;

Vue.mixin({
    computed: {
        $loggedUser() {
            return vuexStore.loggedUser;
        },
        Constantes() {
            return Constantes;
        },
    },
    methods: {
        /**
         *
         * @param {Array} perfis
         * @returns
         */
        $possuiPerfil(perfis: string[]) {
            return (
                //@ts-ignore
                this.$loggedUser.perfis.filter((perf: any) => {
                    return perfis.includes(perf.nome);
                }).length > 0
            );
        },
        $showErrorModal(msg) {
            VuedalsBus.$emit("new", {
                component: Modal,
                props: {
                    msg: msg,
                    type: "error",
                },
            });
        },
        $showSuccessModal(msg) {
            VuedalsBus.$emit("new", {
                component: Modal,
                props: {
                    msg: msg,
                    type: "success",
                },
            });
        },
    },
});

new Vue({
    //@ts-ignore
    nprogress: nprogress,
    el: "#app",
    router: router,
    store: store,
    render: (h) => h(App),
});
