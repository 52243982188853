import Vuex from "vuex";

import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import Vue from "vue";
import Usuario from "cathedra-aulas-vue/src/domain/Seguranca/Usuario";
import CarrinhoItemEntity from "cathedra-aulas-vue/src/domain/Vendas/CarrinhoItemEntity";
import CarrinhoEntity from "cathedra-aulas-vue/src/domain/Vendas/CarrinhoEntity";

@Module({ name: "VuexStore" })
export class VuexStore extends VuexModule {
    loggedUser: Usuario = new Usuario();
    carrinho: CarrinhoEntity = { id: null, itens: [], dataCriacao: null };
    checkout: any = { passo: 1 };

    @Mutation
    _setLoggedUser(novoUser: Usuario) {
        this.loggedUser = novoUser;
    }

    @Action({ commit: "_setLoggedUser" })
    setLoggedUser(novoUser: Usuario) {
        return novoUser;
    }

    /**
     * @returns true se this.loggedUser !== undefined && this.loggedUser !== null
     */
    get loggado(): boolean {
        return this.loggedUser && this.loggedUser.username !== undefined;
    }

    /**
     * Não usar esse.
     * @param novoItem
     */
    @Mutation
    _addProduto(novoItem: CarrinhoItemEntity) {
        this.carrinho.itens.push(novoItem);
    }

    @Action
    addProduto(novoProduto: CarrinhoItemEntity) {
        this.context.commit("_addProduto", novoProduto);
    }

    @Mutation
    _removeItem(item: CarrinhoItemEntity) {
        const encontrados = this.carrinho.itens.filter((it) => it.produto.id == item.produto.id);

        if (encontrados.length) {
            for (let i = 0; i < this.carrinho.itens.length; i++) {
                if (encontrados.includes(this.carrinho.itens[i])) {
                    this.carrinho.itens.splice(i, 1);
                }
            }
        }
    }

    @Action
    removeItem(item: CarrinhoItemEntity) {
        this.context.commit("_removeItem", item);
    }

    @Mutation
    _setCarrinho(carrinho: CarrinhoEntity) {
        this.carrinho = carrinho;
    }

    @Action
    setCarrinho(carrinho: CarrinhoEntity) {
        this.context.commit("_setCarrinho", carrinho);
    }

    @Mutation
    _checkoutProximoPasso(checkout: any) {
        this.checkout = checkout;
    }

    @Action
    checkoutProximoPasso(): any {
        this.checkout.passo = this.checkout.passo + 1;
        this.context.commit("_checkoutProximoPasso", this.checkout);
    }

    @Mutation
    _checkoutRetornaPasso() {
        if (this.checkout.passo > 1) this.checkout.passo = this.checkout.passo - 1;
    }

    @Action({ commit: "_checkoutRetornaPasso" })
    checkoutRetornaPasso(): any {
        return null;
    }
}

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: { VuexStore },
});
