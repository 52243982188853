import AxiosHttp from "../AxiosHttp";
import CarrinhoEntity from "./CarrinhoEntity";
import CarrinhoItemEntity from "./CarrinhoItemEntity";
import { CupomEntity } from "./PedidoEntity";

export default class VendasService extends AxiosHttp {
    constructor() {
        super();
    }

    listarPedidos(dataInicial: string, dataFinal: string) {
        return this.http.get(`vendas/pedido`, { params: { dataInicial: dataInicial, dataFinal: dataFinal } }).then(
            (res) => {
                return res.status == 200 ? res.data : null;
            },
            (err) => {
                console.error(err);
                throw new Error(err);
            }
        );
    }

    atualizarFlagsPedido(pedido: any) {
        return this.http.put("vendas/pedido", pedido).then(
            (res) => {
                return res.status == 200 ? res.data : null;
            },
            (err) => {
                console.error(err);
                throw new Error(err);
            }
        );
    }

    obterCarrinhoPorUsername(username: string): Promise<CarrinhoEntity> {
        return this.http.get(`vendas/carrinho`, { params: { username: username } }).then((res) => {
            return res.status == 200 ? res.data : null;
        });
    }

    adicionarAoCarrinho(carrinho: CarrinhoItemEntity, erroSeExiste: boolean = true): Promise<any> {
        return this.http.post(`vendas/carrinho/item`, carrinho, { params: { erroSeExiste } }).then((res) => {
            return res.status == 200 ? res.data : null;
        });
    }

    iniciarFinalizacaoCompra(carrinho: any) {
        return this.http.post("vendas/finalizar", carrinho).then((res) => {
            return res.status == 200 ? res.data : null;
        });
    }

    /**
     *
     * @param item Obrigatório possuir o carrinhoId e produto.id
     * @returns
     */
    removerCarrinhoItem(item: CarrinhoItemEntity): Promise<void> {
        return this.http.delete(`vendas/carrinho/${item.carrinhoId}/item/${item.produto.id}`).then((res) => {
            return res.status == 200 ? res.data : null;
        });
    }

    obterPublicKey(): Promise<string> {
        return this.http.get("vendas/pagamento/publicKey").then((res) => {
            return res.status == 200 ? res.data : null;
        });
    }

    obterPagamento(referencia: string) {
        return this.http.get(`vendas/pagamento/${referencia}`).then((res) => {
            return res.status == 200 ? res.data : null;
        });
    }

    calcularTotal(total: number, cupom: CupomEntity): Promise<number> {
        return this.http.post("vendas/calcularTotal", cupom, { params: { total } }).then((res) => {
            return res.status == 200 ? res.data : null;
        });
    }
}
