
import { Component, Watch } from "vue-property-decorator";
// @ts-ignore
import TransitionHeight from "cathedra-aulas-vue/src/components/shared/painel/TransitionHeight.vue";
import VendasSiteService from "../../../domain/vendas/VendasSiteService";
import CarrinhoItemEntity from "cathedra-aulas-vue/src/domain/Vendas/CarrinhoItemEntity";

import VendasBaseComponent from "../../VendasBaseComponent";

@Component({ components: { TransitionHeight } })
export default class MenuItemCarrinho extends VendasBaseComponent {
    tela: any = { exibir: false, prodAdded: "" };
    vendasService: VendasSiteService;

    get itens(): CarrinhoItemEntity[] {
        return this.store.carrinho.itens;
    }

    constructor() {
        super();
        this.vendasService = new VendasSiteService();
    }

    @Watch("itens")
    itensCarrinhoMudou() {
        this.tela.prodAdded = "piscar-in";
        setTimeout(() => (this.tela.prodAdded = ""), 600);
    }
}
