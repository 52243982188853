
// @ts-ignore
import logo from "cathedra-aulas-vue/src/assets/img/logo.png";
import SegurancaService from "cathedra-aulas-vue/src/domain/Seguranca/SegurancaService";
import { menu } from "../../../configs/router";
import MenuItemCarrinho from "./MenuItemCarrinho.vue";
// @ts-ignore
import CampoTexto from "cathedra-aulas-vue/src/components/shared/campos/CampoTexto.vue";
import VendasBaseComponent from "../../VendasBaseComponent";
import { Prop, Component } from "vue-property-decorator";
import Usuario from "cathedra-aulas-vue/src/domain/Seguranca/Usuario";
import Utils from "cathedra-aulas-vue/src/domain/Utils";

@Component({ components: { MenuItemCarrinho, CampoTexto } })
export default class Menu extends VendasBaseComponent {
    @Prop({ required: false, default: "X.Y.Z" })
    versao: string;
    logoImg: any = logo;

    menuExpandido: any = {};
    segService: SegurancaService;

    usuario: Usuario = new Usuario();

    created() {
        this.segService = new SegurancaService();
    }

    get rotasPermitidas() {
        return menu.filter((rota) => {
            return (
                rota.meta.menu &&
                (!rota.perfisPermitidos ||
                    // @ts-ignore
                    (this.store.loggedUser.perfis &&
                        rota.perfisPermitidos.filter((pf) => {
                            // @ts-ignore
                            this.store.loggedUser.perfis.map((p) => p.nome).includes(pf);
                        }).length > 0))
            );
        });
    }

    logout() {
        this.segService.logout().then(() => {
            this.$emit("logout", true);
        });
    }

    login() {
        this.segService.logar(this.usuario).then(() => {
            this.$emit("login", true);
            Utils.exibirMsg("Usuário logado com sucesso.");
        });
    }
    toggleMenu(route: any) {
        if (this.menuExpandido[route.meta.titulo]) this.$set(this.menuExpandido, route.meta.titulo, false);
        else this.$set(this.menuExpandido, route.meta.titulo, true);
    }
}
