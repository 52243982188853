
import { Component } from "vue-property-decorator";
import TextoLeitura from "../../shared/painel/TextoLeitura.vue";
import ProdutosPainel from "../../shared/painel/ProdutosPainel.vue";
import VendasBaseComponent from "../../VendasBaseComponent";

@Component({ components: { TextoLeitura, ProdutosPainel } })
export default class HomePage extends VendasBaseComponent {
    mounted() {
        // super.addMercadoPagoScript("scripts", "home");
    }
}
