import Produto from "../produto/Produto";

export default class CarrinhoItemEntity {
    carrinhoId: number;
    produto: Produto;
    qtde: number;

    constructor(produto: Produto, qtde: number) {
        this.carrinhoId = null;
        this.produto = produto;
        this.qtde = qtde;
    }
}
