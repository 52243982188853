
import { Component, Watch } from "vue-property-decorator";
// @ts-ignore
import { routes } from "./configs/router";
import Menu from "./components/shared/menu/Menu.vue";
// @ts-ignore
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import GeralService from "cathedra-aulas-vue/src/domain/geral/GeralService";
import VendasBaseComponent from "./components/VendasBaseComponent";
import VendasSiteService from "./domain/vendas/VendasSiteService";
import SegurancaSiteService from "./domain/seguranca/SegurancaSiteService";

@Component({ components: { menus: Menu, NprogressContainer: NprogressContainer } })
export default class App extends VendasBaseComponent {
    // @ts-ignore
    routes: any = routes;

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    versao: string = require("./../package.json").version;
    geralService: GeralService;
    loginPage = "#/";
    segService: SegurancaSiteService;
    vendasService: VendasSiteService;

    mounted() {
        this.geralService.version().then((sys: any) => (this.versao = sys.version));
        this.vendasService.carregarItensCarrinho();
    }

    @Watch("store.loggedUser.username")
    usernameMudou(newUsername: string) {
        if (newUsername) {
            this.vendasService.carregarItensCarrinho();
        }
    }

    @Watch("$route")
    routeMudou() {
        // @ts-ignore
        this.$jQuery("html, body").animate({ scrollTop: 0 }, "fast");
    }

    created() {
        this.segService = new SegurancaSiteService();
        this.geralService = new GeralService();
        this.vendasService = new VendasSiteService();

        // @ts-ignore
        this.$http.interceptors.response.use(
            // @ts-ignore
            (response) => {
                return response;
            },
            // @ts-ignore
            (error) => {
                let response = error.response;
                if (response) {
                    if (response.status == 400) {
                        // @ts-ignore
                        this.$showErrorModal(response.data.message);
                        return Promise.reject(error);
                    } else if (response.status == 500) {
                        // @ts-ignore
                        this.$showErrorModal(
                            "Erro não esperado, favor contate a admistração do Cathedra: " + response.data.message ||
                                JSON.stringify(response.data)
                        );
                        return Promise.reject(error);
                    } else if (response.status == 401) {
                        return response;
                    }
                } else {
                    return Promise.reject(error);
                }
            }
        );
        this.updateLoggedUser();
    }

    updateLoggedUser() {
        this.segService.updateLoggedUser();
    }
}
