<template>
    <div class="bg-white text-lg text-justify p-5" style="font-weight: 100">
        <slot></slot>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
