import SegurancaService from "cathedra-aulas-vue/src/domain/Seguranca/SegurancaService";
import CarrinhoItemEntity from "cathedra-aulas-vue/src/domain/Vendas/CarrinhoItemEntity";
import VendasService from "cathedra-aulas-vue/src/domain/Vendas/VendasService";

import { store, VuexStore } from "../VuexStore";
import { getModule } from "vuex-module-decorators";
import CarrinhoEntity from "cathedra-aulas-vue/src/domain/Vendas/CarrinhoEntity";
import { CupomEntity } from "cathedra-aulas-vue/src/domain/Vendas/PedidoEntity";

export default class VendasSiteService extends VendasService {
    // @ts-ignore
    store: VuexStore = getModule(VuexStore, store);

    segService: SegurancaService;

    constructor() {
        super();
        this.segService = new SegurancaService();
    }

    adicionarAoCarrinho(item: CarrinhoItemEntity): Promise<any> {
        if (this.store.loggado) {
            return super.adicionarAoCarrinho(item).then((newItem) => this.adicionarAoCarrinhoLocal(newItem));
        } else {
            return this.adicionarAoCarrinhoLocal(item);
        }
    }

    adicionarAoCarrinhoLocal(item: CarrinhoItemEntity) {
        if (this.store.carrinho.itens.filter((it) => it.produto.id == item.produto.id).length > 0) {
            return Promise.reject(`O produto ${item.produto.nome} já está no carrinho.`);
        } else {
            this.store.addProduto(item);
            this.saveLocalStorage();
            return Promise.resolve(() => item);
        }
    }

    obterCarrinhoPorUsername(username?: string): Promise<CarrinhoEntity> {
        if (this.store.loggado) {
            return super.obterCarrinhoPorUsername(username).then((carrinho) => {
                if (!carrinho) {
                    return new CarrinhoEntity();
                } else return carrinho;
            });
        } else {
            if (localStorage.carrinho) {
                try {
                    const carrinho = JSON.parse(localStorage.carrinho);
                    if (!carrinho) {
                        Promise.resolve(new CarrinhoEntity());
                    } else return Promise.resolve(carrinho);
                } catch {
                    console.error("localStorage.carrinho não é um JSON válido.");
                    delete localStorage.carrinho;
                    return Promise.resolve(new CarrinhoEntity());
                }
            } else {
                return Promise.resolve(new CarrinhoEntity());
            }
        }
    }

    carregarItensCarrinho(): void {
        this.obterCarrinhoPorUsername(this.store.loggedUser.username).then((carrinho: CarrinhoEntity) => {
            const promises = [];
            if (this.store.loggado) {
                for (const item of this.store.carrinho.itens) {
                    if (carrinho == null || !carrinho.itens.map((i) => i.produto.id).includes(item.produto.id)) {
                        promises.push(super.adicionarAoCarrinho(item, false).then((it) => carrinho.itens.push(it)));
                    }
                }
            }
            Promise.all(promises).then(() => {
                this.store.setCarrinho(carrinho);
                this.saveLocalStorage();
            });
        });
    }

    removerCarrinhoItem(item: CarrinhoItemEntity): Promise<void> {
        if (this.store.loggado) {
            return super.removerCarrinhoItem(item).then(() => this.removerCarrinhoItemLocal(item));
        } else {
            this.removerCarrinhoItemLocal(item);
        }
    }

    saveLocalStorage() {
        localStorage.carrinho = JSON.stringify(this.store.carrinho);
    }

    removerCarrinhoItemLocal(item: CarrinhoItemEntity) {
        this.store.removeItem(item);
        this.saveLocalStorage();
    }

    iniciarFinalizacaoCompra(carrinho: any) {
        return super.iniciarFinalizacaoCompra(carrinho).then((data: any) => {
            return data;
        });
    }

    obterCupomPorCodigo(codigoCupom: string): Promise<CupomEntity> {
        return this.http.get(`vendas/cupom/codigo/${codigoCupom}`).then(
            (res: any) => {
                return res.status == 200 ? res.data : null;
            },
            (err: any) => {
                throw new Error(err);
            }
        );
    }

    calcularTotal(total: number, cupom: CupomEntity) {
        return this.http.post(`vendas/calcularTotal`, cupom, { params: { total: total } }).then(
            (res: any) => {
                return res.status == 200 ? res.data : null;
            },
            (err: any) => {
                throw new Error(err);
            }
        );
    }
}
