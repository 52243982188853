import CarrinhoItemEntity from "./CarrinhoItemEntity";

export default class CarrinhoEntity {
    id: number = null;

    dataCriacao: string;

    itens: Array<CarrinhoItemEntity>;

    constructor() {
        this.itens = [];
    }
}
